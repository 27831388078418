<template>
  <div class="animated fadeIn">
    <b-card
      header-tag="header"
      footer-tag="footer">
      <div slot="header">
        <strong> Filtros </strong>
        <b-button variant="primary" class="text-white float-right" size="xs" @click="filtrosAvancados()">Avançado</b-button>
      </div>
      <div class="p-1 mt-2">
        <b-row class="mb-1">
          <b-col sm="12" md="4" class="d-md-block">
            <b-button-toolbar class="float-left">
              <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.periodo" @change="changePeriodo" name="radiosBtn">
                <b-form-radio class="mx-0" value="Hoje">Hoje</b-form-radio>
                <b-form-radio class="mx-0" value="Ontem">Ontem</b-form-radio>
                <b-form-radio class="mx-0" value="Semana">Semana</b-form-radio>
                <b-form-radio class="mx-0" value="Mes">Mês</b-form-radio>
                <b-form-radio class="mx-0" value="Periodo">Período</b-form-radio>
              </b-form-radio-group>
            </b-button-toolbar>
          </b-col>
          <b-col sm="12" md="8">
            <div class="custom-control custom-checkbox pt-1">
              <input type="checkbox" class="custom-control-input" id="chkDesconsiderarPecas" v-model="filtro.fields.desconsiderarPecas">
              <label class="custom-control-label" for="chkDesconsiderarPecas">Desconsiderar devoluções em "Peças"</label>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
            <multiselect v-model="filtro.fields.gestao" :options="filtro.listas.gestao" placeholder="Todas as Gestões" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
              </template>
              <template slot="clear">
                <div class="multiselect__clear" v-if="filtro.fields.gestao.length" @mousedown.prevent.stop="clearAll('gestao')"></div>
              </template>
            </multiselect>
          </b-col>
          <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
            <multiselect v-model="filtro.fields.centres" :options="filtro.listas.centresFiltradosPorGestao" placeholder="Todas as Lojas" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
              </template>
              <template slot="clear">
                <div class="multiselect__clear" v-if="filtro.fields.centres.length" @mousedown.prevent.stop="clearAll('centres')"></div>
              </template>
            </multiselect>
          </b-col>
          <b-col sm="4" class="mb-1 mb-md-0">
            <multiselect v-model="filtro.fields.formaVenda" :options="filtro.listas.formaVenda" placeholder="Todas as Formas de Venda" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
              </template>
              <template slot="clear">
                <div class="multiselect__clear" v-if="filtro.fields.formaVenda.length" @mousedown.prevent.stop="clearAll('formaVenda')"></div>
              </template>
            </multiselect>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pr-1 mb-1 mb-md-0">
            <b-form-input :plain="true" v-model="filtro.fields.dataIni" type="date" @change="dataChanged"></b-form-input>
          </b-col>
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pl-1 pl-md-3 mb-1 mb-md-0">
            <b-form-input :plain="true" v-model="filtro.fields.dataFim" type="date" @change="dataChanged"></b-form-input>
          </b-col>
          <b-col cols="6" sm="6" md="1" class="pr-1">
            <b-form-select :plain="true" v-model="filtro.fields.limit" :options="filtro.listas.limits"></b-form-select>
          </b-col>
          <b-col cols="6" sm="6" md="2" class="pt-2 pl-md-0">
            <label class="mb-0 text-nowrap">menos vendidos</label>
          </b-col>
          <b-col sm="1" v-if="!filtro.filtrosAvancados">
            <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
              <i class="fa fa-search"></i>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-1" v-if="filtro.filtrosAvancados">
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pr-1 mb-1">
            <multiselect v-model="filtro.fields.fornecedores" :options="filtro.listas.fornecedores" placeholder="Todos os Fornecedores" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
              </template>
              <template slot="clear">
                <div class="multiselect__clear" v-if="filtro.fields.fornecedores.length" @mousedown.prevent.stop="clearAll('fornecedores')"></div>
              </template>
            </multiselect>
          </b-col>
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pr-1 mb-1">
            <multiselect v-model="filtro.fields.grupos" :options="filtro.listas.grupos" placeholder="Todos os Grupos" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
              </template>
              <template slot="clear">
                <div class="multiselect__clear" v-if="filtro.fields.grupos.length" @mousedown.prevent.stop="clearAll('grupos')"></div>
              </template>
            </multiselect>
          </b-col>
          <b-col cols="6" sm="6" md="4" class="mb-1">
            <multiselect v-model="filtro.fields.subgrupos" :options="filtro.listas.subgrupos" placeholder="Todos os Subgrupos" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
              </template>
              <template slot="clear">
                <div class="multiselect__clear" v-if="filtro.fields.subgrupos.length" @mousedown.prevent.stop="clearAll('subgrupos')"></div>
              </template>
            </multiselect>
          </b-col>
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pr-1 mb-1">
            <multiselect v-model="filtro.fields.vendedores" :options="filtro.listas.vendedores" placeholder="Todos os Vendedores" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
              </template>
              <template slot="clear">
                <div class="multiselect__clear" v-if="filtro.fields.vendedores.length" @mousedown.prevent.stop="clearAll('vendedores')"></div>
              </template>
            </multiselect>
          </b-col>
          <b-col sm="1">
            <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
              <i class="fa fa-search"></i>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <c-table :table-data="items" :fields="fields" striped small caption="Produtos Menos Vendidos" :per-page="50"></c-table>
  </div>
</template>

<script>
import moment from 'moment';
import API from '@/core/api';
import cTable from '@/core/components/Table.vue'
import Multiselect from 'vue-multiselect'
import session from '@/shared/session';
import lojasPorGestaoMultiMixin from '@/core/mixins/lojasPorGestaoMulti.mixin.js';

export default {
  name: 'ProdutosMenosVendidos',
  components: {
    cTable,
    Multiselect
  },
  mixins:[
    lojasPorGestaoMultiMixin
  ],
  data: () => {
    return {
      filtro: {
        listas: {
          gestao: session.get('gestoes').filter(c => c.value !== 0),
          centres: session.get('lojas').filter(c => c.value !== 0),
          centresDefault: session.get('lojas'),
          formaVenda: session.get('formas').filter(c => c.value !== 0),
          grupos: session.get('grupos'),
          subgrupos: session.get('subgrupos'),
          fornecedores: session.get('fornecedores'),
          vendedores: session.get('vendedores'),
          limits: [{value: 0, text: 'Todos'},{value: 25, text: '25'},{value: 50, text: '50'},{value: 100, text: '100'}],
          centresFiltradosPorGestao: []
        },
        fields: {
          periodo: 'Hoje',
          gestao: [],
          centres: [],
          formaVenda: [],
          grupos: [],
          subgrupos: [],
          fornecedores: [],
          vendedores: [],
          dataIni: moment().format('YYYY-MM-DD'),
          dataFim: moment().format('YYYY-MM-DD'),
          limit: 50,
          ordem: false,
          desconsiderarPecas: false
        },
        filtrosAvancados: false
      },
      items: [],
      fields: [
        {key: 'ordem', label: 'Nº', sortable: true},
        {key: 'codigo', label: 'Código', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'referencia', label: 'Referência', sortable: true},
        {key: 'descricao', label: 'Descrição', sortable: true},
        {key: 'complemento', label: 'Complemento', sortable: true},
        {key: 'promocao', label: 'Promoção', sortable: true},
        {key: 'estoque', label: 'Estoque', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'compra', label: 'Compra', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'venda', label: 'Vendas R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'pecas', label: 'Peças', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'percentual', label: '%', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'acumulado', label: 'Acum.', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'}
      ]
    }
  },
  created() {
    this.search();
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          const produtosAPI = new API('api/rank-produtos');
          const {data} = await produtosAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.items = this.parseList(data);
        }
      } catch (e) {
        console.error(e)
      }
    },
    validaPeriodo() {
      const { dataIni, dataFim } = this.filtro.fields;
      let invalid = false;

      if (
        dataIni === '' ||
        dataFim === '' ||
        moment(dataIni).isAfter(moment(dataFim)) ||
        !moment(dataIni).isValid() ||
        !moment(dataFim).isValid()
      ) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    dataChanged() {
      this.filtro.fields.periodo = 'Periodo';
    },
    changePeriodo(periodo) {
      switch(periodo) {
        case 'Hoje': {
          this.filtro.fields.dataIni = moment().format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().format('YYYY-MM-DD');
          break;
        }
        case 'Ontem': {
          this.filtro.fields.dataIni = moment().subtract(1, 'day').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().subtract(1, 'day').format('YYYY-MM-DD');
          break;
        }
        case 'Semana': {
          this.filtro.fields.dataIni = moment().startOf('week').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('week').format('YYYY-MM-DD');
          break;
        }
        case 'Mes': {
          this.filtro.fields.dataIni = moment().startOf('month').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('month').format('YYYY-MM-DD');
          break;
        }
        default: return;
      }
      this.search();
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const {
        grupos,
        subgrupos,
        fornecedores,
        vendedores,
        centres,
        formaVenda,
        limit
      } = filters;

      // Listas
      const listaGrupos       = this.filtro.listas.grupos;
      const listaSubgrupos    = this.filtro.listas.subgrupos;
      const listaFornecedores = this.filtro.listas.fornecedores;
      const listaVendedores   = this.filtro.listas.vendedores;

      const listaGestoes      = this.filtro.listas.gestao;
      const listaCentres      = this.filtro.listas.centres;
      const listaFormasVenda  = this.filtro.listas.formaVenda;

      // Filtros
      filter.grupos       = grupos.length === 0 ? [] : grupos;
      filter.subgrupos    = subgrupos.length === 0 ? [] : subgrupos;
      filter.fornecedores = fornecedores.length === 0 ? [] : fornecedores;
      filter.vendedores   = vendedores.length === 0 ? [] : vendedores;

      // filter.gestao       = gestao.length === 0 ? [] : gestao;
      filter.centres      = centres.length === 0 ? [] : centres;
      filter.formaVenda   = formaVenda.length === 0 ? [] : formaVenda;

      // Join array
      filter.grupos       = (filter.grupos && filter.grupos.map(c => c.value).join(',')) || '0';
      filter.subgrupos    = (filter.subgrupos && filter.subgrupos.map(c => c.value).join(',')) || '0';
      filter.fornecedores = (filter.fornecedores && filter.fornecedores.map(c => c.value).join(',')) || '0';
      filter.vendedores   = (filter.vendedores && filter.vendedores.map(c => c.value).join(',')) || '0';

      // filter.gestao       = (filter.gestao && filter.gestao.map(c => c.value).join(',')) || '0';
      filter.centres      = (filter.centres && filter.centres.map(c => c.value).join(',')) || '0';
      filter.formaVenda   = (filter.formaVenda && filter.formaVenda.map(c => c.value).join(',')) || '0';

      filter.limit = Number(limit);
      return filter;
    },
    parseList(data) {
      if (data && data.length === 0) {
        return [];
      }

      let acumulado = 0;
      data.reduce((acc, current) => {
        if (acc) {
          acc.acumulado = Number(acc.percentual)
          acumulado = acc.acumulado
        }
        current.acumulado = (Number(acumulado) + Number(current.percentual))
        acumulado = current.acumulado
      })

      return data.map(d => {
        d.promocao = d.promocao === 0 ? 'Não' : 'Sim',
        d.estoque = parseInt(d.estoque)
        d.compra = parseInt(d.compra)
        d.pecas = parseInt(d.pecas)
        d.percentual = Number(d.percentual).toFixed(2)
        d.acumulado = Number(d.acumulado || 100).toFixed(2)
        d.abc = this.getABC(d.acumulado - d.percentual)
        return d
      });
    },
    limitFormat(value) {
      if (value > 999) {
        value = value.slice(0, -1);
      }
      return value;
    },
    getABC(acumulado) {
      if (acumulado < 70) {
        return 'A'
      } else if (acumulado > 90) {
        return 'C'
      }
      return 'B'
    },
    filterLojas() {
      const { gestao, centres } = this.filtro.fields;
      const { centresDefault } = this.filtro.listas;
      this.filtro.listas.centres = gestao === 0 ? centresDefault
        : centresDefault.filter(l => l.gestao === gestao || l.value === 0)

      const gestaoCentres = centresDefault.find(c => c.value === centres)
      if (gestaoCentres.gestao !== gestao) {
        this.filtro.fields.centres = 0
      }
    },
    filtrosAvancados() {
      if (this.filtro.filtrosAvancados) {
        this.filtro.fields.grupos = [];
        this.filtro.fields.subgrupos = [];
        this.filtro.fields.fornecedores = [];
        this.filtro.fields.vendedores = [];
      }

      setTimeout(() => {this.filtro.filtrosAvancados = !this.filtro.filtrosAvancados},200);
    },
    clearAll(field) {
      if (this.filtro.fields[field] && this.filtro.fields[field].length > 0) {
        this.filtro.fields[field] = [];
      }
    }
  }
}
</script>
